import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Layout.scss";
import { Route, Routes, useLocation } from "react-router-dom";

import GlobalFooter from "../common/GlobalFooter/GlobalFooter";
import GlobalHeader from "../common/GlobalHeader/GlobalHeader";
import { GeoCheck } from "./GeoCheck/GeoCheck";
import { Breadcrumb } from "../common/breadcrumb/Breadcrumb";
import i18n from "../../i18n";
import { FeatureFlags } from "../../components/common/FeatureFlags/FeatureFlags";
import Feature from "../common/FeatureFlags/Feature/Feature";
import LicAgreementHeader from "../common/LicAgreementHeader/LicAgreementHeader";
import {
  API_PATHS,
  DEFAULT_LANG,
  ERROR_MESSAGES,
  LABELKEYS_DASHBOARD,
  ACCESS_STATUS,
  URLS,
  HTTP_STATUS_CODE,
  TECHNICAL_PUBLICATIONS,
} from "../../helper/constants";
import { useGetConfigurationsQuery } from "../../services/configurations";
import Loader from "../common/Loader/Loader";
import { TechpubsLinks } from "../common/TechpubLinks/TechpubLinks";
import { useTranslation } from "react-i18next";
import NoDataAvailable from "../common/NoDataAvailable/NoDataAvailable";
import SearchBar from "./SearchBar/SearchBar";
import { LicAgreementPopup } from "./LicAgreementPopup/LicAgreementPopup";
import MyFavorites from "./MyFavorites/MyFavorites";
import MyWatchList from "./MyWatchList/MyWatchList";
import ViewCollection from "./ViewCollection/ViewCollection";
import MyLibrary from "./MyLibrary/MyLibrary";
import TempRevisions from "./TempRevisions/TempRevisions";
import ReviewRequest from "./ReviewRequest/ReviewRequest";
import RelatedPublication from "./SearchResults/RelatedPublication";
import Downloads from "./Downloads/Downloads";
import RequestForm from "./RequestForm/RequestForm";
import { isObjectWithProperties, aemSiteLink } from "../../assets/js/utils";
import SubscriptionRenewal from "../common/SubscriptionRenewal/SubscriptionRenewal";
import WelcomePopup from "./WelComePopUp/WelcomePopup";
import CallToAECS from "./CallToAECS/CallToAECS";
const Layout = (props) => {
  const { t } = useTranslation();
  const [displaypage, setDisplayPage] = useState(true);
  const [nodatavailable, setNoDataAvailable] = useState(true);
  const [onLoad, setOnLoad] = useState(false);
  const [atLeastOnceLoggedIn, setAtLeastOnceLoggedIn] =
    useState<boolean>(false);
  const { data, error, isLoading } = useGetConfigurationsQuery(
    API_PATHS.LANG_API_PATH + "?lang=" + DEFAULT_LANG.EN_US_LANG
  );
  const {
    tokenValid,
    isLoadingToken,
    download_totalRecords,
    token,
    loadingDownload,
  } = useSelector((state: any) => state.config);

  const { location, resLocationStatus, isLoadingLocation } = useSelector(
    (state: any) => state.location
  );
  const selector = useSelector((state: any) => state.welcomePopUp);
  const { licdata, isLoadingLic, resLicStatus } = useSelector(
    (state: any) => state.licagreement
  );
  const { mylibrary, isLoadingmyLibrary } = useSelector(
    (state: any) => state.mylibrary
  );
  const configData = useSelector(
    (state: any) => state.config?.configData || {}
  );
  let tokenValidate: any = "";
  if (token?.token) {
    tokenValidate = token?.token;
  }
  const approvedStatus = ACCESS_STATUS.APPROVED;
  const checkStatus = tokenValid?.isSuccess;
  const toolAccess =
    checkStatus === true ? tokenValid?.result?.KEY_TOOL_ACCESS : "";

  if (
    resLocationStatus === HTTP_STATUS_CODE.STATUS_200 &&
    isLoadingLocation === false
  ) {
    setTimeout(function () {
      setDisplayPage(false);
    }, 10);
  }

  const initFeatures = () => {
    if (!sessionStorage.getItem("flags")) {
      sessionStorage.setItem("flags", JSON.stringify(FeatureFlags));
    }
  };
  const dispatch = useDispatch<any>();
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/favorites");
  const isDownloads = pathname.includes("/downloads");
  const isWatchlist = pathname.includes("/notifications");
  const isViewLocation = pathname.includes("/viewcollection");
  const isLibrary = pathname.includes("/subscriptions");
  const isTempRevisions = pathname.includes("/temprevisions");
  const isReviewReq = pathname.includes("/rev_request");
  const isRelatePublication = pathname.includes("/relatedpub");
  const isRequestForm = pathname.includes("/subscription_request");

  useEffect(() => {
    initFeatures();
    if (data?.isSuccess) {
      const resources = data?.result;
      i18n.init({
        resources,
        fallbackLng: "enUS",
        debug: true,
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
      });
    } else if (!data?.isSuccess && error) {
      if (tokenValidate && tokenValidate != "") {
        //window.location.reload();
      }
      //throw ERROR_MESSAGES.LANG_FILE_NOT_FOUND;
    }
  }, [dispatch, data, error, i18n]);

  useEffect(() => {
    initFeatures();
  }, []);

  // Detect if user is logged in at least once and update state accordingly
  useEffect(() => {
    if (tokenValid?.isSuccess === true) {
      setAtLeastOnceLoggedIn(true);
    }
  }, [tokenValid]);

  // This useEffect is used to catch when user is
  // logged out by inactivity or doesnt have access to the tool,
  // redirect automatically to SSO url if one of the conditions is met.
  useEffect(() => {
    if (
      atLeastOnceLoggedIn &&
      (!isObjectWithProperties(tokenValid) ||
        tokenValid?.result?.KEY_TOOL_ACCESS !== approvedStatus ||
        tokenValid?.isSuccess === false)
    ) {
      redirectToSSO();
    }
  }, [tokenValid]);

  const redirectToSSO = () => {
    const ssoUrl = aemSiteLink();
    if (ssoUrl && ssoUrl?.length > 0) {
      window.location.href = ssoUrl;
    }
  };

  return (
    <React.Fragment>
      {/* Global Header Component */}
      {<GlobalHeader />}
      {tokenValid &&
        isLoadingToken === false &&
        checkStatus === true &&
        toolAccess != "" &&
        toolAccess == approvedStatus &&
        licdata?.licdata === 204 && <LicAgreementPopup />}
      <div className="maincontainer aeromain row">
        {tokenValid && <Breadcrumb />}
        <div  className={isViewLocation || isTempRevisions || isRelatePublication ? "row parentrow cls-subscriptionContainer":"row parentrow"}>

          {tokenValid && (
            <div className="Pagetitle col-md-4">
              <h2 className="title"> {TECHNICAL_PUBLICATIONS}</h2>
            </div>
          )}

          {tokenValid &&
            isLoadingToken === false &&
            checkStatus === true &&
            toolAccess != "" &&
            isLoadingmyLibrary === false &&
            toolAccess == approvedStatus && (
              <>
                <TechpubsLinks />
                {/*<SubscriptionRenewal/>*/} 
 	      </>
            )}

            {tokenValid &&
            isLoadingToken === false &&
            checkStatus === true &&
            toolAccess != "" &&
            isLoadingmyLibrary === false &&
            toolAccess == approvedStatus &&
            selector.welcomePopUp === false &&
            (
              <>
                <WelcomePopup onLoad={onLoad} setOnLoad={setOnLoad} />
              </>
            )}
              
            {tokenValid &&
              isLoadingToken === false &&
              checkStatus === true &&
              toolAccess != "" &&
              toolAccess == approvedStatus && (
                <>
                  <CallToAECS/>
              </>
            )}
        </div>
      </div>

      {(!tokenValid || isLoadingToken === true) && <Loader />}

      {tokenValid &&
        isLoadingToken === false &&
        (checkStatus === false ||
          toolAccess == "" ||
          toolAccess != approvedStatus) && (
          <>
            <NoDataAvailable />
          </>
        )}
      {tokenValid &&
        isLoadingToken === false &&
        checkStatus === true &&
        toolAccess == approvedStatus && (
          <>
            <Feature name="Search Bar">
              {!isDashboard &&
                !isWatchlist &&
                !isViewLocation &&
                !isLibrary &&
                !isTempRevisions &&
                !isReviewReq &&
                !isRelatePublication &&
                !isDownloads &&
                !isRequestForm && <SearchBar />}
            </Feature>

            <Routes>
              {isDownloads && (
                <Route path="/downloads" element={<Downloads />} />
              )}
              {isDashboard && (
                <Route path="/favorites" element={<MyFavorites />} />
              )}{" "}
              {isWatchlist && (
                <Route path="/notifications" element={<MyWatchList />} />
              )}{" "}
              {isViewLocation && (
                <Route
                  path="/viewcollection/:p1"
                  element={<ViewCollection />}
                />
              )}{" "}
              {isLibrary && (
                <Route path="/subscriptions" element={<MyLibrary />} />
              )}{" "}
              {isTempRevisions && (
                <Route path="/temprevisions/:t1" element={<TempRevisions />} />
              )}{" "}
              {isReviewReq && (
                <Route path="/rev_request" element={<ReviewRequest />} />
              )}{" "}
              {isRelatePublication && (
                <Route
                  path="/relatedpub/:r1"
                  element={<RelatedPublication />}
                />
              )}{" "}
              {isRequestForm && (
                <Route path="/subscription_request/:userPubId" element={<RequestForm />} />
              )}
            </Routes>
          </>
        )}
      <main>{props.children}</main>
      {tokenValid &&
            isLoadingToken === false &&
            checkStatus === true &&
            toolAccess != "" &&
            isLoadingmyLibrary === false &&
            toolAccess == approvedStatus && (
              <>               
                <LicAgreementHeader />
              </>
            )}
      <GlobalFooter />
    </React.Fragment>
  );
};

export default Layout;
