import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  HIDE_RIGHT_PANEL_STATUS,
  LABELKEYS_SEARCH_RESULTS,
  NULL_VALUE,
} from "../../../helper/constants";
import QuickLinks from "../../pages/SearchResults/QuickLinks";
import MetaDataDisplay from "./MetaDataDisplay";
import { useSelector } from "react-redux";
import ViewAll from "./ViewAll";
import moment from "moment";

const MetaData = (props: any) => {
  const [searchMeataData, setSearchMetaData] = useState<any>([]);
  const [showText, setShowText] = useState(false);
  const [searchindex, setSearchIndex] = useState(Number);
  const [showMore, setShowMore] = useState(true);
  const [showClassName, setShowClassName] = useState([]);
  const [addtionalData, setAdditionalData] = useState<any>([]);
  const [attributes, setAttributes] = useState<any>([]);
  const [params, setParams] = useState<any>([]);
  const configData = useSelector(
    (state: any) => state.config?.configData || {}
  );
  const { portalPermissions, loadingPortal, user_details } = useSelector(
    (state: any) => state.config
  );
  // const searchData =
  const showMoreVal = props.showMore !== undefined ? props.showMore : true;

  const formatData = (attribute, data, viewAll) => {
    let dataDisplay = data._source[params[attribute.data]]
      ? data._source[params[attribute.data]]
      : NULL_VALUE;
    if (attribute.isFormatDate === true) {
      dataDisplay =
        data._source[params[attribute.data]] &&
        data._source[params[attribute.data]] !== undefined
          ? formatDate(data._source[params[attribute.data]])
          : NULL_VALUE;
    }

    if (attribute.extraParamData && attribute.extraParamData.length > 0) {
      for (let i = 0; i < attribute.extraParamData.length; i++) {
        dataDisplay =
          dataDisplay +
          attribute.extraParamSeparator[i] +
          attribute.extraParamPrefix[i] +
          data._source[params[attribute.extraParamData[i]]];
      }
    }
    // if (attribute.maxLength && viewAll === true) {
    //   dataDisplay = dataDisplay;
    // }
    if (
      attribute.searchCondition &&
      attribute.searchCondition != "" &&
      data._source[params[attribute.displayConditionalType[0]]] !== undefined &&
      data._source[params[attribute.displayConditionalType[0]]] !== null
    ) {
      const newAttrSearch =
        data._source[params[attribute.displayConditionalType[0]]];
      dataDisplay =
        dataDisplay.toLowerCase() == attribute.searchCondition.toLowerCase()
          ? dataDisplay
          : newAttrSearch[0][params[attribute.displayConditionalType[1]]];
    }

    return dataDisplay;
  };

  useEffect(() => {
    setShowText(false);
    setShowMore(showMoreVal);
    setParams(configData.NATIVE_SEARCH_API_PARAMS);

    if (
      props.searchData !== undefined &&
      props?.searchData?.hits?.total?.value > 0
    ) {
      if (showMoreVal === true) {
        setAttributes(props.attributes.slice(0, props.showMoreAttrLength));
        setAdditionalData(
          props.attributes.slice(
            props.showMoreAttrLength,
            props.attributes.length
          )
        );
      } else {
        setAttributes(props.attributes);
      }
      setSearchMetaData(props.searchData?.hits);
    }
  }, [props.searchData]);

  const showPartString = (str, strlength) => {
    let partString = "";

    if (str && str?.length > 0) {
      //let partString = str;
      //const ary1 = str.split(",");
      const ary1 = [...str];
      if (ary1.length > strlength) {
        partString = ary1.splice(0, strlength).join(", ");
      } else {
        partString =
          ary1.includes("N") && ary1.includes("A")
            ? ary1.toString().replace(/,/g, "")
            : ary1.join(",");
      }
    } else {
      partString = NULL_VALUE;
    }
    return partString;
  };

  const showMoreFiled = (index, isshow) => {
    setSearchIndex(index);
    setShowText(isshow);
  };

  const formatDate = (value) => {
    return moment(value).format("DD MMM Y");
    //const date = new Date(value.replace("T", " "));
    // const date = new Date(Date.parse(value));
    // const day = date.toLocaleString("default", { day: "2-digit" });
    // const month = date.toLocaleString("default", { month: "short" });
    // const year = date.toLocaleString("default", { year: "numeric" });
    // return day + " " + month + " " + year;
  };
  const { t } = useTranslation();

  const replaceHypen = (text: string): string => {
    return text ? text.replace(/-/g, "\u2011") : "";
  };

  return (
    <div>
      {props?.searchData?.hits?.total?.value > 0 &&
        searchMeataData?.hits?.map((searchdata, index) => (
          <div className="record1 item" key={index}>
            <div className="seacrhresultLeft">
              <div className="head-search itemTitle">
                <h6>{replaceHypen(searchdata?._source?.title)}</h6>
              </div>

              {attributes.map((item, attrIndex) => {
                return (
                  <>
                    <MetaDataDisplay
                      label={t(item.label)}
                      data={formatData(item, searchdata, false)}
                      parseString={showPartString}
                      viewAllShow={
                        item.maxLength !== undefined &&
                        searchdata._source[params[item.data]] !== null &&
                        typeof searchdata._source[params[item.data]] ==
                          "object" &&
                        searchdata._source[params[item.data]].length >
                          item.maxLength
                      }
                      maxLength={
                        item.maxLength !== undefined ? item.maxLength : 0
                      }
                      pubId = {searchdata._source?.pub_uuid}      
                    />
                  </>
                );
              })}
              {showMore === true && addtionalData.length > 0 && (
                <>
                  <div
                    className={
                      showMore !== true
                        ? ""
                        : showText && searchindex === index
                        ? "show-more"
                        : "show-less"
                    }
                  >
                    {addtionalData.map((item, attrIndex) => {
                      return (
                        <>
                          <MetaDataDisplay
                            label={t(item.label)}
                            data={formatData(item, searchdata, false)}
                            viewAllShow={
                              item.maxLength !== undefined &&
                              searchdata._source[params[item.data]] !== null &&
                              typeof searchdata._source[params[item.data]] ==
                                "object" &&
                              searchdata._source[params[item.data]].length >
                                item.maxLength
                            }
                            parseString={showPartString}
                            maxLength={
                              item.maxLength !== undefined ? item.maxLength : 0
                            }
                            attributeData={item.data}
                            pubId = {searchdata._source?.pub_uuid}      
                          />
                          {/* {item.maxLength !== undefined && typeof searchdata._source[params[item.data]]=='object' && searchdata._source[params[item.data]].length > item.maxLength  && <ViewAll data={formatData(item, searchdata, true)} />} */}
                        </>
                      );
                    })}
                  </div>
                </>
              )}

              {showMore === true && (
                <div className="sub-head1 shomorelink">
                  <a
                    onClick={() =>
                      showMoreFiled(index, !(showText && searchindex === index))
                    }
                  >
                    {showText && searchindex === index ? (
                      <span>
                        {t(LABELKEYS_SEARCH_RESULTS.SHOW_LESS)}
                        <i className="arrow up"></i>
                      </span>
                    ) : (
                      <span>
                        {t(LABELKEYS_SEARCH_RESULTS.SHOW_MORE)}
                        <i className="arrow down"></i>
                      </span>
                    )}{" "}
                  </a>
                </div>
              )}
            </div>
            {searchdata?._source?.publication_status !== undefined &&
            HIDE_RIGHT_PANEL_STATUS.includes(
              searchdata?._source?.publication_status?.toLowerCase()
            ) ? (
              " "
            ) : props.type == "TR" ? (
              searchdata?._source?.export_lic_description?.toLowerCase() ==
              "no license" ? (
                portalPermissions.result.isInternal === true ? (
                  <div>
                    <QuickLinks
                      data={searchdata}
                      searchResults={props?.searchData}
                      id={index}
                      seacrhVal={props.seacrchval}
                      type={props.type}
                      udc={searchdata?._source?.udc}
                      licFlag={searchdata?._source?.export_lic_description}
                      parentSubscriptionFlag={props.parentSubscriptionFlag}
                      subscriptionFlag={
                        searchdata?._source?.is_subscription_flag
                      }
                      fileStreamerParams={
                        props.fileStreamerParams ? props.fileStreamerParams : ""
                      }
                    ></QuickLinks>
                  </div>
                ) : searchdata?._source?.is_subscription_flag === true ? (
                  <div>
                    <QuickLinks
                      data={searchdata}
                      searchResults={props?.searchData}
                      id={index}
                      seacrhVal={props.seacrchval}
                      type={props.type}
                      udc={searchdata?._source?.udc}
                      licFlag={searchdata?._source?.export_lic_description}
                      parentSubscriptionFlag={props.parentSubscriptionFlag}
                      subscriptionFlag={
                        searchdata?._source?.is_subscription_flag
                      }
                      fileStreamerParams={
                        props.fileStreamerParams ? props.fileStreamerParams : ""
                      }
                    ></QuickLinks>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )
            ) : (
              <div className="rightpanel">
                <QuickLinks
                  data={searchdata}
                  searchResults={props?.searchData}
                  id={index}
                  seacrhVal={props.seacrchval}
                  type={props.type}
                  licFlag={searchdata?._source?.export_lic_description}
                  parentSubscriptionFlag={props.parentSubscriptionFlag}
                  subscriptionFlag={searchdata?._source?.is_subscription_flag}
                  fileStreamerParams={
                    props.fileStreamerParams ? props.fileStreamerParams : ""
                  }
                ></QuickLinks>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default MetaData;
