import React, { useEffect, useState } from "react";

import { FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./MyFavorites.scss";
import { dummyData } from "../../../data";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableFooter,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import Loader from "../../common/Loader/Loader";
import {
  HTTP_STATUS_CODE,
  FAVOURITE_TABLE,
  ERROR_MSG,
  TABLEPAGINATION,
  RECORDS_PER_PAGE_SEARCH_RESULTS,
} from "../../../helper/constants";
import { useTranslation } from "react-i18next";
import ErrorModal from "../../common/ErrorModal/ErrorModal";
import TablePaginations from "../TablePaginations/TablePaginations";
import { FacetFilterActions } from "../../../storage/reducers/facetFilterReducer";
import { Console } from "console";
import { RequestFormAction } from "../../../storage/reducers/requestFormReducer";
import moment from "moment";
export const MyFavorites = () => {
  const dispatch = useDispatch();
  const [favroites, setFavroites] = useState<any>([]);
  const [totalPageNumber, setTotalPageNumber] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [count, setCount] = useState(0);
  const [errMSg, setErrMsg] = useState("");
  const [errMSgRv, setErrMsgForRv] = useState("");
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [confrimFavRemove, setConfirmRemove] = useState(false);
  const [show, setShow] = useState(false);
  const [pubtoremove, setPubNumberToRemove] = useState(false);
  const [topFavSize, setTopFavSize] = useState<any>([]);
  const [errMSG, setErorState] = useState({ falg: false, msg: "" });
  const { t } = useTranslation();
  const { configData, token } = useSelector((state: any) => state.config);
  const recordsPerPage = RECORDS_PER_PAGE_SEARCH_RESULTS;

  let tokenValidate: any = "";
  if (token?.token) {
    tokenValidate = token?.token;
  }

  const formatDate = (value) => {
    const date = value.split("T");
    return moment(date[0]).format("DD MMM Y");
    // const date = new Date(Date.parse(value));
    // const day = date.toLocaleString("default", { day: "2-digit" });
    // const month = date.toLocaleString("default", { month: "short" });
    // const year = date.toLocaleString("default", { year: "numeric" });
    // return day + " " + month + " " + year;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getFavroites();
    dispatch(
      RequestFormAction.getRequestFormData({
        requestDataObj: "",
      })
    );
  }, []);

  const getFavroites = async (pageNumber = 1) => {
    try {
      setLoader(true);
      const response = await fetch(
        `/api/v1/favorite?pageNo=${pageNumber}&pageSize=${TABLEPAGINATION.RowsPerPage}`,
        {
          method: "GET",
          headers: {
            cookie:
              "OptanonAlertBoxClosed=2022-11-10T11:47:36.193Z; 2261-token=`${tokenValidate}`; OptanonConsent=isIABGlobal=false&datestamp=Thu+Nov+24+2022+18^%^3A20^%^3A11+GMT^%^2B0530+(India+Standard+Time)&version=202210.1.0&hosts=&consentId=22d538a6-47b8-49af-bd4b-b1efd51b4763&interactionCount=1&landingPath=NotLandingPage&groups=C0001^%^3A1^%^2CC0003^%^3A1^%^2CC0002^%^3A1^%^2CC0004^%^3A1&geolocation=IN^%^3BMH&AwaitingReconsent=false",
          },
        }
      );
      const data = await response.json();

      if (data?.message?.isSuccess === true) {
        enableDisableCheckboxes(
          data?.message?.result?.favouriteElasticResponse,
          data?.message?.result?.isTopFavSize
        );
        setTotalPageNumber(
          Math.ceil(
            data?.message?.result.totalRecords / TABLEPAGINATION.RowsPerPage
          )
        );
        setTopFavSize(data?.message?.result?.isTopFavSize);
        setCount(favroites.length);
        setLoader(false);
      } else if (data?.message?.isSuccess === false) {
        setErrMsg(data?.message?.result);
        setErorState({ falg: true, msg: data?.message?.result });
        setLoader(false);
        setFavroites([]);
      } else {
        setErrMsg("Something Went Wrong..");
        setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
        setLoader(false);
      }
    } catch (error) {
      setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };

  const removefavriotes = (data) => {
    setShow(true);
    setPubNumberToRemove(data);
  };

  const handleClose = () => {
    setShow(false);
    setConfirmRemove(false);
  };
  const confirmRemove = async () => {
    setDisabled(true);
    try {
      setLoader1(true);
      const response = await fetch("/api/v1/favorite/delete", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pubId: pubtoremove,
        }),
      });

      const data = await response;
      if (data === undefined) {
        const dataTemp = await response.json();
        if (dataTemp !== undefined && dataTemp?.message?.isSuccess === false) {
          setErrMsgForRv("Oops! Something Went Wrong");
          setLoader1(false);
        }
      }

      if (response.status === 200) {
        setConfirmRemove(true);
        const changePgae =
          favroites.length == "1" && currentPage != "1"
            ? currentPage - 1
            : currentPage;
        setCurrentPage(changePgae);
        getFavroites(changePgae);
        setLoader1(false);
        setDisabled(false);
      } else if (response.status === 204) {
        setConfirmRemove(true);
        setErrMsgForRv("Content Not Found");
        setErorState({ falg: true, msg: t(ERROR_MSG.NO_DATA_FOUND) });
        setLoader1(false);
      } else if (response.status === 401) {
        setConfirmRemove(true);
        setErrMsgForRv("Unauthorized..!");
        setErorState({ falg: true, msg: t(ERROR_MSG.UNATHORIZED) });
        setLoader1(false);
      } else {
        setErrMsgForRv("Oops! Somthing Went Wrong");
        setLoader1(false);
        setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
      }
    } catch (error) {
      setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };

  const onChange = () => {
    setErorState({ falg: false, msg: "sucsess" });
  };
  const enableDisableCheckboxes = (
    favroites: any = [],
    selectedTopFiveNumber
  ) => {
    if (favroites) {
      if (selectedTopFiveNumber >= 5) {
        for (let i = 0; i < favroites.length; i++) {
          favroites[i].disabled =
            favroites[i].isTopFavourite === true ? false : true;
        }
      } else {
        for (let i = 0; i < favroites.length; i++) {
          favroites[i].disabled = false;
        }
      }
    }
    setFavroites([...favroites]);
  };

  const addTopFavrorites = async (pubid, chekedvalue) => {
    try {
      const response = await fetch("/api/v1/favorite", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pubId: pubid,
          isTopFavourite: chekedvalue,
        }),
      });

      if (HTTP_STATUS_CODE.STATUS_200 === response.status) {
      } else if (response.status === 401) {
        setErorState({ falg: true, msg: t(ERROR_MSG.UNATHORIZED) });
      } else {
        setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
      }
    } catch (error) {
      setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };
  const [list, setList] = React.useState<any>([]);

  const getChekedFav = (e, index, data) => {
    addTopFavrorites(e.target.value, e.target.checked);
    const findIndex = favroites.findIndex((fav) => fav.pubId == e.target.value);
    favroites[findIndex].isTopFavourite = e.target.checked ? true : false;
    const topFiveLength = e.target.checked ? topFavSize + 1 : topFavSize - 1;
    setTopFavSize(topFiveLength);
    enableDisableCheckboxes(favroites, topFiveLength);
  };

  const parentPageChange = (page) => {
    // setTotalPageNumber(page);
    setCurrentPage(page);
    getFavroites(page);
  };
  const OnClickOfLableLink = (data) => {
    dispatch(
      FacetFilterActions.getSelectedFilterData({
        facetObjectVal: "",
        facetPayloadVal: {
          queryString: data,
          pageable: {
            pageNo: 1,
            pageSize: recordsPerPage.toString(),
            sortBy: "",
            sortDir: "",
          },
        },
      })
    );
  };
  return (
    <>
      <div className="tableComponent">
        <h1>Favorites</h1>
        <Card className="tablecontainer">
          <Table className="TableWrapper">
            <TableHead>
              <TableRow>
                <TableCell>{t(FAVOURITE_TABLE.TITLE)}</TableCell>
                <TableCell>{t(FAVOURITE_TABLE.PUBLICATION_NO)}</TableCell>
                <TableCell>{t(FAVOURITE_TABLE.REVISION_DATE_NO)}</TableCell>
                <TableCell>{t(FAVOURITE_TABLE.TYPE)}</TableCell>
                <TableCell>{t(FAVOURITE_TABLE.TOP_5)}</TableCell>
                <TableCell>{t(FAVOURITE_TABLE.REMOVE_FAVORITE)}</TableCell>
              </TableRow>
            </TableHead>

            {loader ? (
              <TableCell colSpan={6}>
                <Loader />
              </TableCell>
            ) : (
              <TableBody>
                {favroites.length === 0 && (
                  <TableCell colSpan={6}>
                    {favroites.length === 0 ? (
                      <h1>{t(FAVOURITE_TABLE.NO_RECORD_FOUND)}</h1>
                    ) : (
                      <h1>{errMSg}</h1>
                    )}
                  </TableCell>
                )}

                {favroites.map((data, index) => (
                  <TableRow className="datarow" key={index}>
                    <>
                      <TableCell className="pubtitlecell">
                        <Link
                          to="/"
                          aria-label="PubTitle"
                          state={data.pubNumber}
                          onClick={() => OnClickOfLableLink(data.pubNumber)}
                        >
                          {data.pubTitle.length <= 40
                            ? `${data.pubTitle}`
                            : `${data.pubTitle.substring(0, 40) + "..."}`}
                        </Link>
                      </TableCell>
                      <TableCell> {data.pubNumber} </TableCell>
                      <TableCell>
                        {formatDate(data.revDate)} / Rev {data.revNumber}
                      </TableCell>
                      <TableCell> {data.pubTypeName}</TableCell>
                      <TableCell className="checkboxcell">
                        <div
                          className={
                            data.isTopFavourite
                              ? "leftcheckbox checked"
                              : "leftcheckbox"
                          }
                        >
                          <input
                            aria-label="Type Preferences"
                            type="checkbox"
                            checked={data.isTopFavourite}
                            onClick={(e) => getChekedFav(e, index, data)}
                            value={data?.pubId}
                            disabled={data.disabled}
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        <a
                          href="#"
                          onClick={() => removefavriotes(data.pubId)}
                          className="removeicon"
                          aria-label="RemoveIcon"
                        ></a>
                      </TableCell>
                    </>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </Card>
      </div>
      {totalPageNumber > 1 && (
        <TablePaginations
          count={totalPageNumber}
          currentPage={currentPage}
          parentPageChange={parentPageChange}
        />
      )}

      <Modal
        className="modal-wrapper licagreementpopup myfavpopup Popupwrapper"
        aria-label="lic poppup"
        show={show}
        id="lic_popup"
      >
        <div className="modalbodycontentlic modalbodycontentPopups  modalbodycontent">
          <Modal.Body className="Modal-body-liccontent">
            {!confrimFavRemove ? (
              <div className="popupcontent">
                <p className="popupmsg popupmessageSuccess">
                  Please confirm that you want this publication to be removed
                  from favorites.
                </p>
              </div>
            ) : (
              <div className="popupcontent">
                <p className="popupmsg popupmessageSuccess">
                  {errMSgRv.length !== 0
                    ? `${errMSgRv}`
                    : "The Publication has been deleted."}
                </p>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="footercontainerlic Popupfooter footercontainer">
              <div className="geocheckbtns">
                <div className="rightbtn">
                  {!confrimFavRemove && !loader1 ? (
                    <>
                      <Button
                        type="button"
                        aria-label="Close Preferences"
                        className="cancelbtn active btn btn-secondary"
                        variant="secondary"
                        onClick={confirmRemove}
                      >
                        REMOVE
                      </Button>
                    </>
                  ) : (
                    <div className="loder-css">{loader1 && <Loader />}</div>
                  )}
                  <Button
                    type="button"
                    aria-label="Close Preferences"
                    className="submitbtn active btn btn-primary"
                    variant="secondary"
                    disabled={disabled}
                    onClick={handleClose}
                  >
                    CLOSE
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
      <ErrorModal
        errorMsg={errMSG.msg}
        errorFlag={errMSG.falg}
        onClose={onChange}
      />
    </>
  );
};
export default MyFavorites;
