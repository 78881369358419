import React, { useEffect, useState } from "react";
import { Breadcrumb as BreadcrumbReact } from "react-bootstrap";
import "./SearchBar.scss";
import { useTranslation } from "react-i18next";
import SearchResults from "../SearchResults/SearchResults";
import CLEAR_SEARCH from "../../../assets/images/arrow-go-back.png";
import {
  ERROR_MSG,
  LABELKEYS_SEARCH_BAR,
  SEARCH_RESULTS_ATTRIBUTES,
  RECORDS_PER_PAGE_SEARCH_RESULTS,
  PATHNAMEURL,
  BROWSE_PUBLICATIONS,
  LABELKEYS_SEARCH_RESULTS,
} from "../../../helper/constants";
import Feature from "../../common/FeatureFlags/Feature/Feature";
import { FeatureFlags } from "../../common/FeatureFlags/FeatureFlags";
import { useLocation } from "react-router-dom";
import MyTopFiveFavorite from "../MyTopFiveFavorite/MyTopFiveFavorite";
import ErrorModal from "../../common/ErrorModal/ErrorModal";
import { FacetFilterActions } from "../../../storage/reducers/facetFilterReducer";
import { DeepFacetFilterActions } from "../../../storage/reducers/deepFilterFacets";
import { useDispatch, useSelector } from "react-redux";
import { listItemTextClasses } from "@mui/material";
import RecentlyDownload from "../RecentlyDownload/RecentlyDownload";
import { useNavigate } from "react-router-dom";
import { ScrollPageActions } from "../../../storage/reducers/scrollPageReducer";
import { Button } from "react-bootstrap";
import "../SearchResults/SearchResults.scss";
import { utilAnalytics } from "../../common/analytics/analyticsUtil";

const SearchBar = (props) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [searchvalue, setSearchValue] = useState<any>("");
  const [msg, setMessage] = useState("");
  const [searchdata, setSearchData] = useState([]);
  const [loder, setLoad] = useState(false);
  const { pathname } = useLocation();
  const location = useLocation();
  const [showBrowseBy, setShowBrowseBy] = useState(true);
  const [inputvalue, setInputValue] = useState<any>("");
  const [errMSG, setErorState] = useState({ falg: false, msg: "" });
  const [isStartOver, setIsStartOver] = useState(false);
  const [urldata, setUrlData] = useState(location.state);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPageSelected, setisPageSelected] = useState(false);
  const [payload, setPayload] = useState<any>([]);
  const [pageSelectedCheck, setPageSelectedCheck] = useState(false);
  const recordsPerPage = RECORDS_PER_PAGE_SEARCH_RESULTS;
  const selectedFactesStateVal = useSelector((state: any) => {
    return state.filteredFacets.selectedFactes;
  });
  const facetObjStateVal = useSelector((state: any) => {
    return state.filteredFacets.facetObj;
  });
  const deepFacetLevels = useSelector((state: any) => {
    return state.deepFacetLevels.deepLevel;
  });

  const scrollPositionPageNo = useSelector((state: any) => {
    return state?.scrollPagePositionLevel?.pageNo;
  });

  const getSerachRecord = (e) => {
    setLoad(true);
    setIsStartOver(false);
    setCurrentPage(1);
    setSearchValue(inputvalue);
    resetFilterFacetObject();
    getSearchResults(inputvalue, true, 1);
  };
  const handleBrowseBy = () => {
    setShowBrowseBy(false);
    setInputValue("");
    resetFilterFacetObject();
    setSearchValue("*");
    getSearchResults("*", true, 1);
  };

  const resetFilterFacetObject = () => {
    dispatch(
      FacetFilterActions.getSelectedFilterData({
        facetObjectVal: "",
        facetPayloadVal: "",
      })
    );
    // ---> make redux object blank
    // use redux payload to update body
  };

  const { t } = useTranslation();
  const handleChange = (event) => {
    setInputValue(event.target.value);
    if (event.target.value === "") {
      setMessage("");
    }
    if (event.key === "Enter") {
      setLoad(true);
      setSearchValue(event.target.value);
      setCurrentPage(1);
      dispatch(
        ScrollPageActions.getScrollPagePosition({
          pageNo: 1,
        })
      );
      setPageSelectedCheck(true);
      setIsStartOver(false);
      resetFilterFacetObject();
      getSearchResults(event.target.value, true, 1);
    }
  };
  const Loader = () => {
    return <div className="loader"></div>;
  };
  const initFeatures = () => {
    if (!sessionStorage.getItem("flags")) {
      sessionStorage.setItem("flags", JSON.stringify(FeatureFlags));
    }
  };
  const getPageableObj = (page) => {
    const pageObj = {
      pageNo: page.toString(),
      pageSize: recordsPerPage.toString(),
      sortBy: "",
      sortDir: "",
    };

    return pageObj;
  };
  const getPayload = (value, pageNo, flag) => {
    const pageableObj = getPageableObj(pageNo);
    let payload: any = {};

    if (!flag) {
      payload = { ...selectedFactesStateVal };
    }
    payload["queryString"] = value === "" ? "" : value.trim();
    payload["pageable"] = pageableObj;

    return payload;
  };
  const getSearchResults = async (searchvalue, flag, pageNumber) => {
    setLoad(true);
    try {
      if (searchvalue === "") {
        searchvalue += " ";
      }
      if (!isStartOver) {
        dispatch(
          FacetFilterActions.getSelectedFilterData({
            facetObjectVal:
              flag === true
                ? undefined
                : facetObjStateVal
                ? facetObjStateVal
                : undefined,
            facetPayloadVal: getPayload(searchvalue, pageNumber, flag),
          })
        );
        dispatch(
          DeepFacetFilterActions.getDeepLevelFacets({
            deepLevel: deepFacetLevels,
          })
        );
      }
      const payload = getPayload(searchvalue, pageNumber, flag);

      const response = await fetch("/api/v1/search/search-results", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // body:
        //   searchvalue === ""
        //     ? JSON.stringify({ queryString: "" })
        //     : JSON.stringify({ queryString: searchvalue }),
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (data?.message?.isSuccess === true) {
        setLoad(false);
        setSearchData(data?.message?.result);
        setMessage("");
        if (data?.message?.result?.hits?.total?.value > 0) {
          setShowBrowseBy(false);
          utilAnalytics(
            true,
            {
              eVar2: inputvalue ? inputvalue : "*",
              eVar90: data?.message?.result?.hits?.total?.value,
              events: "event1",
              linkName: "Successful Technical Publications Search",
            },
            pathname
          );
        } else {
          setShowBrowseBy(true);
          utilAnalytics(
            true,
            {
              eVar90: 0,
              eVar2: inputvalue ? inputvalue : "",
              events: "event1, event2",
              linkName: "Unsuccessful Technical Publications Search",
            },
            pathname
          );
        }
      } else if (
        data?.message?.isSuccess === false &&
        data?.message?.statusCode === 400
      ) {
        setErorState({ falg: true, msg: t(ERROR_MSG.INVALID_SEARCH_KEYWORD) });
        setShowBrowseBy(true);
      } else {
        setLoad(false);
        setMessage(data?.message?.result);
        setErorState({ falg: true, msg: data?.message?.result });
      }
    } catch (error) {
      setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };

  const onClickStartOverBtn = () => {
    setIsStartOver(true);
    resetFilterFacetObject();
    setSearchValue("*");
    setInputValue("");
    dispatch(
      ScrollPageActions.getScrollPagePosition({
        pageNo: 1,
      })
    );
    setPageSelectedCheck(true);
    getSearchResults("*", true, 1);
    setCurrentPage(1);
  };

  useEffect(() => {
    const pubNoVal = window.location.search.split("=")[1];
    if (
      pubNoVal &&
      window.location.search.split("=")[0].split("?")[1] === "pubno" &&
      !isStartOver
    ) {
      const pubNumberValue = decodeURIComponent(decodeURIComponent(pubNoVal));
      setSearchValue(pubNumberValue);
      setInputValue(pubNumberValue);
      getSearchResults(pubNumberValue, false, 1);
    } else {
      if (selectedFactesStateVal?.queryString !== undefined && !isStartOver) {
        setInputValue(selectedFactesStateVal?.queryString);
        setSearchValue(selectedFactesStateVal?.queryString);
      }

      if (
        selectedFactesStateVal === "" &&
        selectedFactesStateVal?.queryString !== undefined &&
        !isStartOver
      ) {
        getSearchResults(selectedFactesStateVal?.queryString, true, 1);
      } else if (selectedFactesStateVal !== "" && !isStartOver) {
        setSearchValue(selectedFactesStateVal?.queryString);
        setInputValue(selectedFactesStateVal?.queryString);
        if (
          scrollPositionPageNo?.length !== 0 &&
          scrollPositionPageNo !== undefined
        ) {
          getSearchResults(
            selectedFactesStateVal?.queryString,
            false,
            scrollPositionPageNo
          );
        } else {
          getSearchResults(selectedFactesStateVal?.queryString, false, 1);
        }
      }
    }

    // if (urldata) {
    //   setInputValue(urldata);
    //   setSearchValue(urldata);
    //   getSearchResults(urldata, true);
    // }
    initFeatures();
  }, [urldata, isStartOver]);

  const [data, setData] = useState("");
  const searchResultToSearchBar = (id, flag) => {
    // setData(childdata);
    const updatedSearchData: any = searchdata;
    updatedSearchData?.hits?.hits.forEach((element) => {
      if (element._id == id) {
        element._source.is_favourite_flag = flag;
      }
    });
    setData(updatedSearchData);
  };

  const onChange = () => {
    setErorState({ falg: false, msg: "sucsess" });
  };

  useEffect(() => {
    if (pathname === PATHNAMEURL) {
      onPageSelected(scrollPositionPageNo);
    }
  }, [scrollPositionPageNo, currentPage]);

  const onPageSelected = (page) => {
    const pageIsSelected = true;
    setisPageSelected(pageIsSelected);
    if (pageSelectedCheck) {
      setCurrentPage(1);
    } else {
      setCurrentPage(page);
    }
    if (
      selectedFactesStateVal?.selectedFacets?.length !== 0 ||
      selectedFactesStateVal?.range !== undefined ||
      selectedFactesStateVal?.fromDate !== undefined
    ) {
      getSearchResults(selectedFactesStateVal?.queryString, false, page);
    } else {
      getSearchResults(selectedFactesStateVal?.queryString, true, page);
    }
    setPageSelectedCheck(false);
  };
  const OnSelectTopFiveFav = (data) => {
    setInputValue(data);
    setSearchValue(data);
    setIsStartOver(false);
    getSearchResults(data, true, 1);
    dispatch(
      FacetFilterActions.getSelectedFilterData({
        facetObjectVal: "",
        facetPayloadVal: {
          queryString: data,
          pageable: {
            pageNo: 1,
            pageSize: recordsPerPage.toString(),
            sortBy: "",
            sortDir: "",
          },
        },
      })
    );
  };
  return (
    <>
      <div className="search-bar customsearchbar">
        <div className="search-bar">
          <div className="searchbar aeroTechPubSearch aem-GridColumn aem-GridColumn--default--12 block-element">
            <input
              type="hidden"
              id="tech-searchbar-errormessage"
              aria-label="display error message"
            />
            <input
              type="hidden"
              value="10"
              id="searchResultsPerPage"
              aria-label="display search results"
            />
            <div className="tech-searchbar">
              <div className="searchbarwrap full-width darkgrey-bg">
                <div
                  className={
                    showBrowseBy ? "blockclass" : "blockclass-startover"
                  }
                >
                  <div className="searchbarcontainer">
                  <div className="find-pub">
                       <h2>{t(LABELKEYS_SEARCH_BAR.SUBHEAD)}</h2>                   
                    </div>  
                    <div className="row">
                      <div className="search__field--aero d-flex input-group custominputgroup">
                        <label htmlFor="search_query">
                          <input
                            title={t(LABELKEYS_SEARCH_BAR.TITLE)}
                            name="search_query"
                            className="form-control searchinput searchbox"
                            id="search_query"
                            aria-label="Search Query"
                            placeholder="Search by Part Number, Pub Number, ATA Code or Keyword"
                            aria-placeholder="Search by Part Number, Pub Number, ATA Code or Keyword"
                            type="text"
                            onChange={handleChange}
                            onKeyDown={handleChange}
                            value={inputvalue}
                          />
                        </label>
                        <div className="search-icon-div">
                          <span className="searchiconfigma"></span>
                          {
                            <span
                              onClick={getSerachRecord}
                              className="searchiconfigma"
                            ></span>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {showBrowseBy ? (
                  <>
                    <div className="or_block">
                      {t(LABELKEYS_SEARCH_RESULTS.OR)}
                    </div>
                    <div className="blockcl">
                      <div className="d-grid gap-2">
                        <Button
                          type="button"
                          aria-label="Close Preferences"
                          className="submitbtn-browseby  active btn btn-primary"
                          variant="secondary"
                          onClick={handleBrowseBy}
                        >
                          {BROWSE_PUBLICATIONS}
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="startover">
                      <Button
                        className="submitbtn-browseby btn btn-primary"
                        onClick={onClickStartOverBtn}
                      >
                        <span className="cls_clear_search">
                          <img src={CLEAR_SEARCH} alt="clear_search" />
                        </span>
                        <span>{t(LABELKEYS_SEARCH_RESULTS.START_OVER)}</span>
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
            {loder && <Loader />}
            {msg != "" && loder === false ? (
              <div className="InvalidRequest">
                <h1 className="error-msg">{msg}</h1>
              </div>
            ) : (
              <Feature name="Search Results">
                <SearchResults
                  dataSearch={searchdata && searchdata}
                  searchval={searchvalue && searchvalue}
                  facetFilter={true}
                  load={loder}
                  showMore={true}
                  attributes={SEARCH_RESULTS_ATTRIBUTES}
                  searchResultToSearchBar={searchResultToSearchBar}
                  setIsStartOver={setIsStartOver}
                  isStartOver={isStartOver}
                  onPageSelected={onPageSelected}
                  currentPage={currentPage}
                  paginator={true}
                  showBrowseBy={showBrowseBy}
                  setShowBrowseBy={setShowBrowseBy}
                />
              </Feature>
            )}

            <div className="searchtipcontainer container">
              <div
                className="float-right searchbar-searchtip"
                id="searchtip-right"
              >
                <a
                  href="/us/en/navigation/services-and-support/support-center"
                  target="_blank"
                  title="search"
                >
                  .
                </a>
              </div>
            </div>
            <div className="searchtipcontainer container">
              <div className="search-tip"></div>
            </div>
          </div>
        </div>
      </div>
      {searchdata.length === 0 &&
      selectedFactesStateVal?.queryString === undefined ? (
        <>
          <MyTopFiveFavorite OnSelectTopFiveFav={OnSelectTopFiveFav} />
          <RecentlyDownload></RecentlyDownload>
        </>
      ) : (
        ""
      )}
      {(searchdata === undefined ||
        (errMSG?.falg &&
          errMSG?.msg === t(ERROR_MSG.INVALID_SEARCH_KEYWORD))) && (
        <ErrorModal
          errorMsg={errMSG.msg}
          errorFlag={errMSG.falg}
          onClose={onChange}
        />
      )}
    </>
  );
};

export default SearchBar;
