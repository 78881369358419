import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  welcomePopUp: false,
};

export const welcomeSlice = createSlice({
  name: "setwelcomePopUp",
  initialState,
  reducers: {
    welcomeReducer: (state, action) => {
      state.welcomePopUp = action.payload;
    },
  },
});
export const { welcomeReducer } = welcomeSlice.actions;
export default welcomeSlice;