import React, { useState } from "react";
import "./LicAgreementHeader.scss";
import { LIC_AGREMENT_LEBELS } from "../../../helper/constants";
import { useTranslation } from "react-i18next";
import axios from "axios";
import fileDownload from "js-file-download";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";

export const LicAgreementHeader = () => {
  const { t } = useTranslation();
  const configData = useSelector((state: any) => state.config?.configData || {});
  const [loader, setLoader] = useState(false);
  const downloadAgreement = async () => {
    setLoader(true);
    await axios({
      url: "/api/v1/lic-agreement/download",
      method: "GET",
      responseType: 'arraybuffer',
    }).then((resp: any) => {
      setLoader(false);
      fileDownload(resp.data, configData.LIC_PDF?.PDF_NAME);
    });
  };


  return (
    <> {loader && <Loader />}
      <div className="lic-agreement-div lic-faq-div">
        <button className='lic-agreement-btn' onClick={downloadAgreement}>
          <a> {t(LIC_AGREMENT_LEBELS.TITLE_FULL)} </a>
        </button>
      </div >
    </>
  );
};
export default LicAgreementHeader;