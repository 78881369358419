import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ModalPopup from "../../common/ModalPopup/ModalPopup";
import { welcomeReducer } from "../../../storage/reducers/welcomePopupReducer";
import { useTranslation } from "react-i18next";
import { WELCOME_POPUP } from "../../../helper/constants";
import "./WelcomePopup.css";


const WelcomePopup = ({ onLoad, setOnLoad }) => {
  const dispatch = useDispatch();
  const [displayPopup, setDisplayPopup] = useState("false");  //to hide the popup the usestte make it as false otherwiase true
  const { t } = useTranslation ();
  const closePopupHandler = () => {
    setDisplayPopup("false");
    setOnLoad(true);
    dispatch(welcomeReducer(true));
  };

  const headerContent = (
    <React.Fragment>
      <div>
      <h2>Important Messages</h2>
      </div>
    </React.Fragment>
  );
  const bodyContent = (
    <React.Fragment>
      <div className="notes-cnt">
        <div class="note-info">
          <span class="info-icon-message">i</span>
          <div>
            <h3>
              {t(WELCOME_POPUP.POPUP_HEADLINE)}
            </h3>
            <p>
              {t(WELCOME_POPUP.SUB_HEADLINE)}
            </p>
            <p>
            {t(WELCOME_POPUP.POPUP_RATE_ESCALATON_INFO)}</p>
            <p>
              {t(WELCOME_POPUP.SUB_HEADLINE_BOTTOM)}
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );


  return (
    <ModalPopup
      showPopup={displayPopup === "true" ? true : false}
      size="lg"
      arialabel="welcome popup"
      centered="true"
      header={headerContent}
      body={bodyContent}
      cancelPopup={closePopupHandler}
    />
  );
};

export default WelcomePopup;