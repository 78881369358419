import React, { useEffect, useState } from "react";
import "./TechpubLinks.scss";
import { useTranslation } from "react-i18next";
import { LOCATION_BREADCRUMB } from "../../../helper/constants";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModalPopup from "./ConfirmModalPopup";
import AdminSite from "../AdminSite/AdminSite";
import { FacetFilterActions } from "../../../storage/reducers/facetFilterReducer";
import { utilAnalytics } from "../analytics/analyticsUtil";

export const TechpubsLinks = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showpopup, setShowPopup] = useState(false);
  const [navbarValue, setNavbarValue] = useState("");
  const urlPath = window.location.pathname.includes("/viewcollection");
  const tempRevUrl = window.location.pathname.includes("/temprevisions");
  const relatedpubUrl = window.location.pathname.includes("/relatedpub");
  const requestForm = window.location.pathname.includes(
    "/subscription_request"
  );
  const { mylibrary } = useSelector((state: any) => state.mylibrary);
  const [myLibraryLinkShow, setmyLibraryLinkShow] = useState(true);
  const [active, setActive] = useState<any>("");
  const { portalPermissions } = useSelector((state: any) => state.config);

  const techPubLinks = useSelector(
    (state: any) => state?.filteredFacets?.techpubLinks
  );
  const navigate = useNavigate();
  const refreshPage = () => {
    // window.location.reload();
  };

  useEffect(() => {
    mylibrary?.result?.totalRecordsFromBMS != "0"
      ? setmyLibraryLinkShow(true)
      : setmyLibraryLinkShow(false);
    if (window.location.pathname === "/") {
      setActive("/publicationSearch");

    } else {
      setActive(window.location.pathname);
    }
  }, [active, techPubLinks]);

  const onClickOfEachLinks: any = (event, redirectVal) => {
    dispatch(
      FacetFilterActions.getSelectedFilterData({
        facetObjectVal: "",
        facetPayloadVal: "",
        redirecTechLink: redirectVal,
      })
    );
    if (requestForm == true) {
      event.preventDefault();
      setShowPopup(true);
      setNavbarValue(redirectVal);
    } else {
      navigate(redirectVal, { replace: true });
    }
    if (redirectVal == "/publicationSearch" && !requestForm) {
      setActive("/publicationSearch");
      navigate("/");
      dispatch(
        FacetFilterActions.getSelectedFilterData({
          redirecTechLink: "/publicationSearch",
        })
      );
      if (window.location.pathname.includes("/")) {
        window.location.reload();
        setActive("/publicationSearch");
      }
    } else if (redirectVal === "/" && requestForm) {
      setShowPopup(true);
      setNavbarValue(redirectVal);
    }
    setActive(redirectVal);
  };
  const redirectToUrl = async () => {
    window.open("https://aerospace.honeywell.com/us/en/support-and-resources/knowledge-and-training/training/topics/technical-publications-faqs");
  };

  return (
    <>
      <ConfirmModalPopup
        show={showpopup}
        setShowPopup={setShowPopup}
        navigationbarVal={navbarValue}
      />
      <div
        className={
          urlPath || tempRevUrl || relatedpubUrl
            ? "AeroTechHeadlineRight viewcollection Psearch col-md-8"
            : "AeroTechHeadlineRight Psearch col-md-8"
        }
      >
        <div className="report-invoice">
          <a href="#" onClick={refreshPage}>
            <Link
              to="/"
              className={active === "/publicationSearch" ? "navbarActive" : ""}
              onClick={(e) => onClickOfEachLinks(e, "/publicationSearch")}
            >
              <span>{t(LOCATION_BREADCRUMB.PUBLICATIONS_SEARCH)}</span>
            </Link>
          </a>
          <Link
            to="/downloads"
            onClick={(e) => {
              onClickOfEachLinks(e, "/downloads");
              utilAnalytics(true, { linkName: "My Downloads" }, "/downloads");
            }}
            className={active === "/downloads" ? "navbarActive" : ""}
          >
            <span>{t(LOCATION_BREADCRUMB.DOWNLOADS)}</span>
          </Link>
          <Link
            to="/favorites"
            className={active === "/favorites" ? "navbarActive" : ""}
            onClick={(e) => {
              onClickOfEachLinks(e, "/favorites");
              utilAnalytics(true, { linkName: "My Favorites" }, "/favorites");
            }}
          >
            <span>{t(LOCATION_BREADCRUMB.MY_FAVORITES)}</span>
          </Link>
          {myLibraryLinkShow && (
            <Link
              to="/subscriptions"
              className={active === "/subscriptions" ? "navbarActive" : ""}
              onClick={(e) => {
                onClickOfEachLinks(e, "/subscriptions");
                utilAnalytics(
                  true,
                  { linkName: "My Subscriptions" },
                  "/subscriptions"
                );
              }}
            >
              <span>{t(LOCATION_BREADCRUMB.MY_LIBRARY)}</span>
            </Link>
          )}
          <Link
            to="/notifications"
            className={active === "/notifications" ? "navbarActive" : ""}
            onClick={(e) => {
              onClickOfEachLinks(e, "/notifications");
              utilAnalytics(
                true,
                { linkName: "My Email Notifications" },
                "/notifications"
              );
            }}
          >
            <span>{t(LOCATION_BREADCRUMB.MY_WATCHLIST)}</span>
          </Link>
          <a onClick={redirectToUrl} className="faq">{t(LOCATION_BREADCRUMB.MY_FAQ)}</a>

          {portalPermissions?.result?.isInternal === true && <AdminSite />}
        </div>
      </div>
    </>
  );
};
