import React, { useEffect, useState } from "react";
import ViewAll from "./ViewAll";
import { useTranslation } from "react-i18next";
import { LABELKEYS_SEARCH_RESULTS } from "../../../helper/constants";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ScrollPositionActions } from "../../../storage/reducers/scrollPositionReducer";
import { parseToLink } from "../../../assets/js/utils";

const MetaDataDisplay = (props: any) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const scrollPositionLevel = useSelector((state: any) => {
    return state?.scrollPositionLevel?.scrollPosition;
  });

  const scrollPositionValid = useSelector((state: any) => {
    return state?.scrollPositionLevel?.scrollValid;
  });
  useEffect(() => {
    if (scrollPositionValid?.[pathname]) {
      window.scrollTo(0, scrollPositionLevel?.[pathname]);
      if (pathname === "/") {
        const updatedScrollPosition = { ...scrollPositionValid };
        delete updatedScrollPosition?.[pathname];
        dispatch(
          ScrollPositionActions.getScrollPosition({
            scrollValid: updatedScrollPosition,
          })
        );
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <div className="sub-head metafield">
      <div className="metafieldLeft">
        <span>{` ${props.label}: `}</span>
      </div>
      <div className="metafieldright">
        <span>
          {" "}
          {props.maxLength > 0
            ? props.label === "Part Numbers"
              ? parseToLink(props.data, props.maxLength,props.pubId)
              : props.parseString(props.data, props.maxLength)
            : props.data}
        </span>

        {props.viewAllShow && (
          <>
            {t(LABELKEYS_SEARCH_RESULTS.COMMA_SEPARATOR)}{" "}
            <ViewAll headerTitle={props.label} data={props.data} pubId={props.pubId}/>
          </>
        )}
      </div>
    </div>
  );
};

export default MetaDataDisplay;
