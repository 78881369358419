import React from "react";
import { Modal } from "react-bootstrap";

const ModalPopup = (props) => {
  return (
    <Modal
      show={props.showPopup}
      size={props.size}
      aria-labelledby={props.arialabel}
      contentClassName={props.contentClassName}
      backdropClassName={props.backdropClassName}
      centered={props.centered}
    >
      <Modal.Header closeButton className="modal-h" onHide={props.cancelPopup}>
        <Modal.Title className="title-modal">{props.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={props?.customModalBodyClass}>{props.body}</Modal.Body>
      <Modal.Footer>{props.footer}</Modal.Footer>
    </Modal>
  );
};

export default ModalPopup;