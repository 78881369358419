import React, { useState } from "react";
import "./GlobalHeader.scss";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";
import "bootstrap/dist/css/bootstrap.css";
import { BRANDING_JS_PATHS } from "../../../helper/constants";
import { getAnalyticstLaunchScriptEnv } from "../../../assets/js/utils";

const GlobalHeader = (props) => {
  const data = useSelector((state: any) => state.headerFooterData);

  const configData = useSelector(
    (state: any) => state.config?.configData || {}
  );

  const [header, setHeader] = useState("");
  // loading header css and js files and creating corresponding link tags

  useEffect(() => {
    if (data) {
      data?.css?.map((cssUrlValue) => {
        const cssFile = document.createElement("link");
        cssFile.href = cssUrlValue;
        cssFile.rel = "stylesheet";
        document.head.appendChild(cssFile);
      });

      setTimeout(function () {
        //your code to be executed after 5 second
        setHeader(data.header);
      }, 500);

      setTimeout(() => {
        if (!document.getElementById("custom_Autosignin")) {
          const scriptFile = document.createElement("script");
          scriptFile.id = "custom_Autosignin";
          scriptFile.src = "/js/custom_Autosignin.js";
          document.body.appendChild(scriptFile);
        }
        if (!document.getElementById("LaunchScript")) {
          const scriptFile = document.createElement("script");
          scriptFile.id = "LaunchScript";
          scriptFile.src = getAnalyticstLaunchScriptEnv();
          document.head.appendChild(scriptFile);
        }
        if (configData && Object.keys(configData).length > 0) {
          if (!document.getElementById("headerFooterJs")) {
            const scriptFile = document.createElement("script");
            scriptFile.id = "headerFooterJs";
            scriptFile.src = BRANDING_JS_PATHS(
              configData.TECHPUB_APP_ID?.APP_ID
            );
            document.body.appendChild(scriptFile);
          }

          // if (!document.getElementById("pageLoadEvent")) {
          //   const scriptFile = document.createElement("script");
          //   scriptFile.id = "pageLoadEvent";
          //   scriptFile.src = "/js/pageloadevent.js";
          //   document.body.appendChild(scriptFile);
          // }
        }

        data?.jsFile?.map((jsValue) => {
          if (jsValue.includes("analytics")) {
            if (!document.getElementById("analyticsUrl")) {
              const scriptFile = document.createElement("script");
              scriptFile.id = "analyticsUrl";
              scriptFile.src = jsValue;
              document.body.appendChild(scriptFile);
            }
          }
        });
      }, 1500);
    }
  }, [data, configData]);
  return (
    <>
      <div id="myAeroNavMnuDvNew">
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(header) }} />
      </div>
    </>
  );
};

export default GlobalHeader;
